import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Img from "gatsby-image";

import { theme } from "../../utils/global-styles/theme";
import CourseCard from "./child-components/CourseCard";

const StartingSoonSection = ({ heading, course, linkText, url }) => {
  const StartingCourseContainer = styled("section")`
      display: grid;
      grid-template-columns: auto auto 76px;
      grid-template-rows: auto 638px 50px;
      grid-template-areas:
        "header header header"
        "image image image"
        ". card .";

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        grid-template-columns: auto;
        grid-template-rows: auto 166px auto;
        grid-template-areas:
          "header"
          "image"
          "card";
      }
    `,
    Header = styled("h1")`
      grid-area: header;
      padding-top: 120px;
      padding-left: 120px;
      margin-bottom: -56px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding-top: 50px;
        padding-left: 14px;
        margin-bottom: -16px;
      }
    `,
    CourseImageBg = styled(Img)`
      grid-area: image;
      height: 100%;
      place-self: center stretch;
      display: grid;
      z-index: -1;
    `,
    CardContainer = styled("div")`
      grid-area: card;
      position: relative;
    `,
    StartingCourseCard = styled(CourseCard)`
      position: absolute;
      bottom: 0;
      right: 0;
      width: 60vw;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        position: relative;
        width: 100%;
      }
    `;

  const { courseImage, shortDescription } = course;

  return (
    <StartingCourseContainer>
      <Header>{heading}</Header>
      <CourseImageBg fluid={courseImage.fluid} />
      <CardContainer>
        <StartingCourseCard
          name={course.title}
          courseId={course.courseId}
          slug={course.slug}
          courseCost={course.courseCost}
          startDate={course.courseStartDate}
          courseDuration={course.courseDuration}
          productType={course.courseType}
          courseTheme={course.theme.theme}
          shortDescription={shortDescription.shortDescription}
          linkText={linkText}
          url={url}
        />
      </CardContainer>
    </StartingCourseContainer>
  );
};

StartingSoonSection.propTypes = {
  heading: PropTypes.string,
  linkText: PropTypes.string,
  url: PropTypes.string,
  course: PropTypes.shape({
    title: PropTypes.string,
    courseId: PropTypes.string,
    courseCost: PropTypes.string,
    courseStartDate: PropTypes.string,
    courseDuration: PropTypes.string,
    courseType: PropTypes.string,
    shortDescription: PropTypes.shape({
      shortDescription: PropTypes.string,
    }),
    courseImage: PropTypes.shape({
      fluid: PropTypes.shape({ srcSet: PropTypes.string }),
    }),
    theme: PropTypes.shape({
      theme: PropTypes.string,
    }),
  }),
};

export default StartingSoonSection;
