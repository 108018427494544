import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import { theme } from "../../utils/global-styles/theme";

import useLearnHeroData from "../../hooks/learn-hero-data";

const LearnLanderHeroSection = () => {
  const { title, description, url, linkText, image } = useLearnHeroData();
  const HeroContainer = styled("section")`
      padding-top: 120px;
      padding-left: 120px;
      padding-bottom: 260px;
      background-image: url("${image.fluid.src}");
      background-size: cover;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding-top: 100px;
        padding-left: 14px;
        padding-bottom: 120px;
      }
    `,
    TitleContainer = styled("h2")`
      padding-bottom: 30px;
      font-size: 130px;
      line-height: 156px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding-bottom: 20px;
        font-size: 40px;
        line-height: 48px;
      }
    `,
    DescriptionContainer = styled("h3")`
      padding-bottom: 50px;
      font-size: 53px;
      line-height: 64px;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        padding-bottom: 20px;
        font-size: 20px;
        line-height: 24px;
      }
    `,
    HeroLink = styled(Link)`
      font-size: 20px;
      line-height: 15px;
      font-weight: bold;
      border-bottom: 4px solid;
      text-transform: uppercase;

      @media only screen and (max-width: ${theme.breakpoints.helper800}) {
        font-size: 13px;
        line-height: 16px;
        border-bottom: 3px solid;
        padding-bottom: 4px;
      }
    `;

  return (
    <HeroContainer className="white primary-bg">
      <TitleContainer>{title}</TitleContainer>
      <DescriptionContainer>{description}</DescriptionContainer>
      <HeroLink
        className="white secondary-border-hover"
        to={`${url}/` || "/courseCatalog/"}
      >
        {linkText}
      </HeroLink>
    </HeroContainer>
  );
};

export default LearnLanderHeroSection;
