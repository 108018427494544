import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { theme } from "../../../utils/global-styles/theme";

import ThemeOne from "../../shared/CourseIcons/ThemeOne";
import ThemeTwo from "../../shared/CourseIcons/ThemeTwo";
import ThemeThree from "../../shared/CourseIcons/ThemeThree";
import ThemeFour from "../../shared/CourseIcons/ThemeFour";
import ThemeFive from "../../shared/CourseIcons/ThemeFive";
import ThemeSix from "../../shared/CourseIcons/ThemeSix";
import RightGoToArrow from "../../CourseCard/Icons/RightGoToArrow";

const moment = require("moment");

const CourseCard = ({
  name,
  courseId,
  slug,
  productType,
  startDate,
  courseCost,
  courseDuration,
  courseType,
  courseTheme,
  className,
  shortDescription,
  linkText,
  url,
}) => {
  linkText = linkText || "Enroll Today";
  url = url || `/course/${slug}`;
  const [hover, setHover] = useState(false);
  const courseStartDisplay = moment(startDate)
    .utcOffset("-0700")
    .format("MMMM Do, YYYY");

  const determineIcon = courseType => {
    const theme =
      courseType !== undefined ? courseType.toLowerCase() : courseType;
    switch (theme) {
      case "redefine success":
        return (
          <ThemeOne
            iconColorClassName={
              hover ? "path-white-fill" : "path-secondary-fill"
            }
            width="35px"
            height="35px"
          />
        );
      case "imagine and develop your idea":
        return (
          <ThemeTwo
            iconColorClassName={
              hover ? "path-white-fill" : "path-secondary-fill"
            }
            width="26px"
            height="29px"
          />
        );
      case "fund your idea":
        return (
          <ThemeThree
            iconColorClassName={
              hover ? "path-white-fill" : "path-secondary-fill"
            }
            width="35px"
            height="34px"
          />
        );
      case "build your business":
        return (
          <ThemeFour
            iconColorClassName={
              hover ? "path-white-fill" : "path-secondary-fill"
            }
            width="35px"
            height="35px"
          />
        );
      case "master innovation":
        return (
          <ThemeFive
            iconColorClassName={
              hover ? "path-white-fill" : "path-secondary-fill"
            }
            width="35px"
            height="35px"
          />
        );
      case "grow as a leader":
        return (
          <ThemeSix
            iconColorClassName={
              hover ? "path-white-fill" : "path-secondary-fill"
            }
            width="35px"
            height="35px"
          />
        );
      case undefined:
        return (
          <ThemeSix
            iconColorClassName={
              hover ? "path-white-fill" : "path-secondary-fill"
            }
            insideColor={hover ? "secondary-fill" : "white-fill"}
            width="30px"
            height="30px"
          />
        );
      default:
        console.warn(
          `"${courseType}" is an invalid courseType passed into CourseCard.js. Check valid courseTypes in with the function determineIcon() in CourseCard.js.`
        );
        return (
          <ThemeSix
            iconColorClassName={
              hover ? "path-white-fill" : "path-secondary-fill"
            }
            width="30px"
            height="30px"
          />
        );
    }
  };

  const Card = styled(Link)`
    display: grid;
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: 120px auto 62px;
    grid-template-areas:
      ". product ."
      "icon title ."
      ". description ."
      ". info ."
      ". enroll .";
    color: ${theme.colors.white};
    font-family: ${theme.fontFaces.headers};
    padding-bottom: 41px;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      grid-template-columns: 63px auto 26px;
      padding-bottom: 39px;
    }
  `;

  const IconContainer = styled.div`
    grid-area: icon;
    place-self: center;
  `;

  const ProductType = styled.div`
    grid-area: product;
    padding-top: 38px;
    font-size: 1em;
    font-weight: normal;
    letter-spacing: -0.25px;
    line-height: 19px;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      padding-top: 26px;
      font-size: 13px;
      line-height: 16px;
    }
  `;

  const Title = styled.div`
    grid-area: title;
    padding-top: 18px;
    font-size: 2em;
    font-weight: bold;
    letter-spacing: -0.91px;
    line-height: 42px;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      padding-top: 11px;
      font-size: 32px;
      line-height: 38px;
    }
  `;

  const ShortDescription = styled.div`
    grid-area: description;
    padding-top: 20px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      padding-top: 10px;
      font-size: 20px;
      line-height: 24px;
    }
  `;

  const BottomInfo = styled.div`
    grid-area: info;
    padding-top: 36px;
    color: ${theme.colors.white};

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      padding-top: 28px;
    }
  `;

  const BottomInfoText = styled.div`
    padding-top: 9px;
    width: max-content;
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
    border-top: 4px solid;
    margin-right: 1.5em;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      font-size: 12px;
      line-height: 14px;
    }
  `;

  const EnrollLink = styled.div`
    grid-area: enroll;
    padding-top: 61px;
    text-transform: uppercase;
    font-size: 17px;
    font-weight: bold;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      padding-top: 25px;
      font-size: 13px;
    }
  `;
  return (
    <Card
      to={`${url}/`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`light-bg secondary-background-hover ${className}`}
    >
      <IconContainer>{determineIcon(courseTheme)}</IconContainer>
      <ProductType className={hover ? `white` : `main`}>
        {productType}
      </ProductType>
      <Title className={hover ? `white` : `main`}>{name}</Title>
      <ShortDescription className={hover ? `white` : `main`}>
        {shortDescription}
      </ShortDescription>
      <BottomInfo>
        <div
          css={css`
            display: flex;
          `}
          className={hover ? `white` : `primary`}
        >
          <BottomInfoText>{courseCost}</BottomInfoText>
          <BottomInfoText>{courseStartDisplay}</BottomInfoText>
          <BottomInfoText>{courseDuration}</BottomInfoText>
        </div>
      </BottomInfo>
      <EnrollLink className={hover ? `white` : `primary`}>
        <div
          css={css`
            display: flex;
          `}
          className={hover ? `white` : `primary`}
        >
          <div
            css={css`
              padding-right: 11px;
            `}
          >
            {linkText}
          </div>
          <RightGoToArrow
            fillColor={hover ? "white-fill" : "primary-fill"}
            width="20px"
            height="20px"
          />
        </div>
      </EnrollLink>
    </Card>
  );
};

CourseCard.propTypes = {
  name: PropTypes.string,
  courseId: PropTypes.string,
  slug: PropTypes.string,
  productType: PropTypes.string,
  startDate: PropTypes.string,
  courseCost: PropTypes.string,
  courseDuration: PropTypes.string,
  courseType: PropTypes.string,
  courseTheme: PropTypes.string,
  className: PropTypes.string,
  shortDescription: PropTypes.string,
  linkText: PropTypes.string,
  url: PropTypes.string,
};

export default CourseCard;
