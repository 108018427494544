import React from "react";
import Layout from "../components/Layout/Layout";
import styled from "@emotion/styled";
import { Link } from "gatsby";

import useCourseStartingSoonData from "../hooks/learn-course-starting-soon-data";
import useCoursesAvailableNowData from "../hooks/learn-courses-available-now-data";
import useInfoCardsData from "../hooks/learn-info-cards-data";
import useTestimonialData from "../hooks/learn-testimonial-quote-data";
import useMarketingModalData from "../hooks/use-marketing-modal-data";
import { theme } from "../utils/global-styles/theme";

import CatalogGrid from "../components/CatalogCard/CatalogGrid";
import LearnLanderHeroSection from "../components/LearnLanderHero/LearnLanderHeroSection";
import StartingSoonSection from "../components/StartingSoon/StartingSoonSection";
import TestimonialSlider from "../components/CourseDetail/child-components/TestimonialSlider";
import CourseTypeBenefits from "../components/CourseTypeBenefits/CourseTypeBenefits";

const moment = require("moment");

// Learn lander page
const Header = styled("h1")`
    padding-top: 120px;
    padding-left: 120px;
    margin-bottom: -56px;
    position: relative;
    z-index: 4;
    font-weight: bold;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      padding-top: 50px;
      padding-left: 14px;
      margin-bottom: -10px;
    }
  `,
  TestimonialQuoteContainer = styled("section")`
    margin-top: 100px;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      margin-top: 0px;
    }
  `,
  AvailableNowRow = styled("section")`
    display: flex;
    align-content: space-between;

    > h1 {
      flex: 1;
    }
  `,
  BrowseCoursesLink = styled(Link)`
    align-self: flex-end;
    border-bottom: 3px solid;
    text-transform: uppercase;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: 50px;
    font-weight: bold;

    @media only screen and (max-width: ${theme.breakpoints.helper800}) {
      display: none;
    }
  `;

const LearnLanderPage = ({ location: { pathname } }) => {
  const startingSoon = useCourseStartingSoonData(),
    availableNowCourses = useCoursesAvailableNowData(),
    infoCards = useInfoCardsData(),
    testimonial = useTestimonialData(),
    marketingModalData = useMarketingModalData();

    let availableCourses = availableNowCourses.courses;
  return (
    <Layout currentPath={pathname} marketingModal={marketingModalData}>
      <LearnLanderHeroSection />
      <StartingSoonSection
        course={startingSoon.courses[0]}
        heading={startingSoon.heading}
        linkText={startingSoon.linkText}
        url={startingSoon.url}
      />
      <AvailableNowRow>
        <Header>{availableNowCourses.heading}</Header>
        <BrowseCoursesLink
          to={`${availableNowCourses.url}/` || "/courseCatalog/"}
          className="primary primary-border secondary-border-hover"
        >
          {availableNowCourses.linkText || "Browse All Courses"}
        </BrowseCoursesLink>
      </AvailableNowRow>
      <section>
        <CatalogGrid courses={availableCourses} isNotCourseCatalogPage={true} />
      </section>
      <section>
      <Header>Find the Right Course</Header>
      {infoCards.map((card, i) => {
        return (
          <CourseTypeBenefits
            index={i}
            margin="0 0 20px 0"
            mobileMargin="0 0 20px 0"
            heading={card.heading}
            description={card.description}
            link={card.link}
            benefit1={card.benefit1}
            benefit2={card.benefit2}
            benefit3={card.benefit3}
            image={card.image}
            imageTint={true}
          />
        );
      })}
      </section>
      <TestimonialQuoteContainer>
        <TestimonialSlider
          testimonials={[testimonial]}
          hasPrimaryBackground={true}
        />
      </TestimonialQuoteContainer>
    </Layout>
  );
};

export default LearnLanderPage;
