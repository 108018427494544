import { graphql, useStaticQuery } from "gatsby";

const useMarketingModalData = () => {
  const data = useStaticQuery(graphql`
    query {
      data: allContentfulPageLayout(
        filter: { title: { eq: "Learn Lander" } }
      ) {
        nodes {
          marketingModal {
            ... on ContentfulMarketingModal {
              id
              cta {
                linkText
                url
              }
              description {
                description
              }
              title
              showAfterPercent
              hubspotListId
              marketingModalImage {
                image {
                  fluid {
                    aspectRatio
                    srcSet
                  }
                }
              }
              formFields
              successTitle
              successDescription
              additionalText {
                json
              }
            }
          }
        }
      }
    }
  `);
  return data.data.nodes[0].marketingModal;
};

export default useMarketingModalData;
