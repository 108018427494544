import { graphql, useStaticQuery } from "gatsby";

const useLearnTestimonialQuoteData = () => {
  const data = useStaticQuery(graphql`
    query {
      learn: allContentfulPageLayout(
        filter: { queryIdentifier: { eq: "Learn Lander" } }
      ) {
        nodes {
          contentModules {
            ... on ContentfulTestimonialQuote {
              authorName
              authorTitle
              testimonialQuote {
                testimonialQuote
              }
            }
          }
        }
      }
    }
  `);

  return data.learn.nodes[0].contentModules.filter(x => x.authorName)[0];
};

export default useLearnTestimonialQuoteData;
