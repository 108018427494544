import { graphql, useStaticQuery } from "gatsby";
import find from "lodash/find";

const useCoursesData = () => {
  const data = useStaticQuery(graphql`
    query {
      learn: allContentfulPageLayout(
        filter: { queryIdentifier: { eq: "Learn Lander" } }
      ) {
        nodes {
          contentModules {
            ... on ContentfulUpcomingCoursesFeaturedBlogs {
              title
              heading
              linkText
              url
              courses {
                ... on ContentfulCourseDetailPage {
                  courseImage {
                    fluid {
                      srcSet
                    }
                  }
                  title
                  courseId
                  slug
                  courseType
                  courseCost
                  courseStartDate
                  courseDuration
                  shortDescription {
                    shortDescription
                  }
                  theme: theme20 {
                    theme
                    themeNumber
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return find(data.learn.nodes[0].contentModules, {
    title: "Learn Lander - Course starting soon",
  });
};

export default useCoursesData;
