import { graphql, useStaticQuery } from "gatsby";
import merge from "lodash/merge";

const useLearnHeroData = () => {
  const data = useStaticQuery(graphql`
    query {
      learn: allContentfulPageLayout(
        filter: { queryIdentifier: { eq: "Learn Lander" } }
      ) {
        nodes {
          heading
          contentModules {
            ... on ContentfulImage {
              image {
                fluid {
                  src
                }
              }
            }
            ... on ContentfulTitleDescription {
              title
            }
            ... on ContentfulLink {
              linkText
              url
            }
          }
        }
      }
    }
  `);
  let d = merge.apply(null, data.learn.nodes[0].contentModules);
  // remap fields to be logical.
  d.description = d.title;
  d.title = data.learn.nodes[0].heading;
  return d;
};

export default useLearnHeroData;
