import { graphql, useStaticQuery } from "gatsby";

const useLearnInfoCardData = () => {
  const data = useStaticQuery(graphql`
    query {
      learn: allContentfulPageLayout(
        filter: { queryIdentifier: { eq: "Learn Lander" } }
      ) {
        nodes {
          contentModules {
            ... on ContentfulBenefitsCard {
              heading
              description
              link {
                url
                linkText
              }
              benefit1
              description1
              benefit2
              description2
              benefit3
              description3
              image {
                fluid {
                  srcSet
                  src
                  sizes
                  base64
                  aspectRatio
                }
              }
            }
          }
        }
      }
    }
  `);

  return data.learn.nodes[0].contentModules.filter(x => x.heading);
};

export default useLearnInfoCardData;
